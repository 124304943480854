import React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { grey } from "@material-ui/core/colors";
import { Typography } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";

import { getInitials } from "../util";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    "&.size-lg": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
  },
  avatar: {
    backgroundColor: grey[500],
    fontSize: "120%",
    width: theme.spacing(8),
    height: theme.spacing(8),
    "&.avatar-size-lg": {
      width: theme.spacing(14),
      height: theme.spacing(14),
      marginTop: 0,
      fontSize: "180%",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  memberData: {
    paddingLeft: theme.spacing(2),
    "& .member-info": {
      fontSize: "90%",
      "& em ": {
        display: "block",
      },
      "& a": {
        color: theme.palette.secondary.main,
      },
      "& small": {
        display: "block",
        marginTop: theme.spacing(1),
      },
    },
    "& .member-name": {
      "& a": {
        color: theme.palette.secondary.main,
      },
    },
    "& .social-profile": {
      display: "inline-block",
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: theme.palette.secondary.main,
      "&:after": {
        display: "none !important",
      },
    },
    "&.member-data-size-lg": {
      paddingLeft: theme.spacing(3),
      "& .member-info": {
        fontSize: "100%",
      },
    },
  },
}));

function VCard({
  id,
  jobTitle,
  name,
  intro,
  phone,
  email,
  className,
  size,
  categories,
  avatar,
  slug,
  profiles,
  exclude = [],
  single,
}) {
  const classes = useStyles();
  if (!name) return <></>;
  phone = !exclude.includes("phone") ? phone : null;
  email = !exclude.includes("email") ? email : null;
  if (!className) className = "";

  let member_name_label = single ? (
    <Typography variant="h2" component="h1">
      {name}
    </Typography>
  ) : (
    <Link to={`${slug}`} onClick={(e) => e.stopPropagation()}>
      {name}
    </Link>
  );
  let member_name_dom = (
    <Typography className="member-name" component={"span"}>
      {member_name_label}
    </Typography>
  );

  // let categories_label = null;

  // if (categories && categories.length > 0) {
  //   categories_label = categories.map(({ id, name }, i) => (
  //     <span key={id}>
  //       {name}
  //       {i < categories.length - 1 && ", "}
  //     </span>
  //   ));
  // }

  let member_info_summary = (
    <Typography className="member-info">
      {jobTitle && <em>{jobTitle}</em>}
      {email && (
        <a
          href={`mailto:${email}`}
          onClick={(e) => e.stopPropagation()}
          rel="noreferrer"
          target="_blank"
        >
          {email}
        </a>
      )}{" "}
      {phone && email ? <>{"- "}</> : ""}
      {phone && <span>{phone}</span>}
      {intro && !exclude.includes("intro") && <small>{intro}</small>}
    </Typography>
  );

  let member_social_profiles = profiles.map(({ site, url }) => (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      title={`${site} profile`}
      className="social-profile"
      key={url}
    >
      {site === "linkedin" ? <LinkedInIcon /> : <TwitterIcon />}
    </a>
  ));

  let card_content = (
    <>
      <Avatar
        className={`${classes.avatar} avatar-size-${size}`}
        alt="Profile Picture"
        src={avatar}
      >
        {getInitials(name)}
      </Avatar>
      <div className={`${classes.memberData} member-data-size-${size}`}>
        <>
          {member_name_dom} {member_info_summary} {member_social_profiles}
        </>
      </div>
    </>
  );

  return single ? (
    <header key={id} className={`${classes.root} ${className} size-${size}`}>
      {card_content}
    </header>
  ) : (
    <div key={id} className={`${classes.root} ${className} size-${size}`}>
      {card_content}
    </div>
  );
}

export default VCard;

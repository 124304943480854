import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => {
  return {
    list: {
      listStyle: "none",
      padding: "0",
      margin: "0",
      "& li:not(:first-child)": {
        marginTop: theme.spacing(1),
      },
      "& a ": {
        color: theme.palette.secondary.main,
        display: "block",
        wordWrap: "break-word",
      },
    },
  };
});

function Links({ links }) {
  const classes = useStyles();
  return (
    <nav>
      <ul className={classes.list}>
        {links.map((a_link) => {
          return (
            <li key={a_link.id}>
              {a_link.external ? (
                <a href={a_link.to} target="_blank" rel="noreferrer">
                  {a_link.label}
                </a>
              ) : (
                <Link to={a_link.to}>{a_link.label}</Link>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default Links;

import React from "react";
import { slugify } from "../util";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Subtitle from "../components/Subtitle";
import Grid from "@material-ui/core/Grid";
import NewsCard from "../components/NewsCard";
import processNodes from "../data/processNodes";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(6),
    "& + .content-row": {
      borderTop: "#e0e0e0 3px solid",
    },
  },
}));

function RecentNews({ id, title, more_path, data }) {
  const classes = useStyles();
  const news = processNodes(data);
  return (
    <aside id={id} className={`content-row ${classes.root}`}>
      {news.length > 0 && (
        <>
          <Container maxWidth="lg">
            <Subtitle
              text={title}
              actionLabel="View all"
              actionPath={more_path}
              id={slugify(title)}
            />
            <Grid container spacing={3}>
              {news.map((article) => (
                <Grid item key={article.id} xs={12} md={4}>
                  <NewsCard {...article} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </>
      )}
    </aside>
  );
}

export default RecentNews;

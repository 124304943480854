import React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  link: {
    width: "50%",
    textDecoration: "none",
    color: "inherit",
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: theme.spacing(2),
      textAlign: "center !important",
      justifyContent: "center !important",
    },
    "&.next": {
      textAlign: "right",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {},
    },
    "& span": {
      display: "block",
      textTransform: "uppercase",
      width: "100%",
      marginBottom: theme.spacing(1),
    },
    "& b": {
      width: "80%",
      padding: "0 5%",
    },
  },
}));

function NextPrev({ next, prev }) {
  const classes = useStyles();
  return (
    <nav className={classes.root}>
      {prev ? (
        <Link to={prev.to} className={`${classes.link} prev`}>
          <span>Previous</span> <ArrowBackIcon /> <b>{prev.label}</b>
        </Link>
      ) : (
        <span />
      )}{" "}
      {next ? (
        <Link to={next.to} className={`${classes.link} next`}>
          <span>Next</span> <b>{next.label}</b> <ArrowForwardIcon />
        </Link>
      ) : (
        <span />
      )}
    </nav>
  );
}

export default NextPrev;

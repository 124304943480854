import React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import ContentBlock from "../components/ContentBlock";
import ToolsCard from "../components/ToolsCard";
import Youtube from "../components/Youtube";
import RecentNews from "./RecentNews";
import VCard from "./VCard";

const useStyles = makeStyles((theme) => ({
  container: {
    "& > a.link": {
      display: "block",
      fontSize: "1.1rem",
      color: theme.palette.primary.main,
    },
    "&:not(.align-center) > *": {
      textAlign: "left",
    },
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(3),
      marginBottom: 0,
    },
  },
  grid: {
    justifyContent: "center",
    "&.single-col": {
      '& div[class*="richtext"], div[class*="container"] > h3.has-content': {
        maxWidth: 800,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
}));

function ContentRow({
  title,
  content,
  quicklinks,
  button,
  youtube,
  recent,
  personnel,
  styles,
}) {
  const classes = useStyles();
  let cols = [];
  if ((content && content.length > 0) || (personnel && personnel.length > 0)) {
    cols.push({
      className: classes.container,
      id: "content-col",
      children: (
        <>
          <Typography
            variant="h3"
            component="h3"
            className={content && content.length > 0 ? "has-content" : "no-content"}
          >
            {title}
          </Typography>
          <div
            className={styles.richtext}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          {button && (
            <Link to={button.uri} className="link">
              {button.title}
            </Link>
          )}
          {personnel && (
            <Grid container spacing={4}>
              {personnel.map((member) => (
                <Grid key={member.id} item xs={12} sm={4}>
                  <VCard {...member} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      ),
    });
  }

  if (quicklinks) {
    cols.push({
      className: "",
      id: "quicklinks-col",
      children: (
        <>
          {typeof quicklinks !== "undefined" && (
            <ToolsCard
              {...quicklinks}
              color="white"
              sidebar
              styles={{ richtext: styles.richtext }}
            />
          )}
        </>
      ),
    });
  }

  if (youtube) {
    cols.push({
      id: "youtube-col",
      className: "",
      children: <>{typeof youtube !== "undefined" && <Youtube {...youtube} />}</>,
    });
  }

  // const col_width = cols.length === 1 ? 9 : 12 / cols.length;
  const col_width = 12 / cols.length;

  return (
    <>
      <ContentBlock>
        <Grid
          container
          spacing={6}
          className={`${classes.grid} ${cols.length === 1 ? "single-col" : ""}`}
        >
          {cols.map((col) => (
            <Grid key={col.id} item xs={12} sm={col_width} className={col.className}>
              {col.children}
            </Grid>
          ))}
        </Grid>
      </ContentBlock>
      {typeof recent !== "undefined" && recent.type === "news" && (
        <RecentNews
          id="recent-news"
          title="Recent News"
          more_path="/news"
          data={recent.data}
        />
      )}
    </>
  );
}

export default ContentRow;

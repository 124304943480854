import React from "react";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    "& a": {
      color: theme.palette.secondary.main,
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      "& span": {
        display: "block",
        paddingLeft: theme.spacing(1),
        fontSize: "1.1rem",
      },
    },
  },
}));

function AnchorNav({ anchors, headerOffset = 110 }) {
  const classes = useStyles();

  function handleClick(e, id) {
    e.preventDefault();
    let target = document.querySelector(`#${id}`);
    if (target) {
      let offsetPosition = target.offsetTop - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }

  return (
    <nav className={classes.root}>
      {anchors.map((anchor) => (
        <a
          key={anchor.id}
          href={`#${anchor.id}`}
          onClick={(e) => handleClick(e, anchor.id)}
        >
          <SubdirectoryArrowRightIcon /> <span>{anchor.title}</span>
        </a>
      ))}
    </nav>
  );
}

export default AnchorNav;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { handleEmail, recaptchaSubmit } from "../util";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    "& > *": {
      marginTop: theme.spacing(2),
      width: "100%",
      "&:first-child": {
        marginTop: 0,
      },
    },
  },
}));

function Contact({ site_data }) {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = React.useState(false);
  const [alert, setAlert] = React.useState([]);
  const [contactName, setContactName] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const [contactSubject, setContactSubject] = React.useState("");
  const [contactComment, setContactComment] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    setIsLoading(true);
    try {
      const valid = await recaptchaSubmit(executeRecaptcha);
      if (!valid) throw new Error("Failed recaptcha");
      const formData = {
        contact_name: contactName,
        contact_email: contactEmail,
        contact_comment: contactComment,
        contact_subject: contactSubject,
      };
      if (
        formData.contact_email.trim().length === 0 ||
        formData.contact_comment.trim().length === 0
      ) {
        throw new Error("Please fill all the required fields");
      }
      handleEmail(formData, site_data.adminEmail, site_data.title)
        .then(() => {
          setAlert(["success", "Thank you, we will get in touch soon."]);
          setIsLoading(false);
          setContactName("");
          setContactEmail("");
          setContactSubject("");
          setContactComment("");
        })
        .catch(() => {
          throw new Error(
            "An error occurred while sending your message, please try again later."
          );
        });
    } catch (err) {
      setAlert(["error", err.message]);
      setIsLoading(false);
    }
  };

  return (
    <>
      {site_data && (
        <Paper
          component="form"
          className={classes.form}
          method="post"
          onSubmit={(e) => handleSubmit(e)}
        >
          {alert.length > 0 && <Alert severity={alert[0]}>{alert[1]}</Alert>}
          <FormControl>
            <TextField
              name="contact_name"
              label="Name"
              variant="outlined"
              value={contactName}
              onChange={(e) => {
                setContactName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              name="contact_email"
              label="Email"
              value={contactEmail}
              type="email"
              variant="outlined"
              required={true}
              onChange={(e) => {
                setContactEmail(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              name="contact_subject"
              label="Subject"
              variant="outlined"
              value={contactSubject}
              onChange={(e) => {
                setContactSubject(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              name="contact_comment"
              label="Comment"
              variant="outlined"
              value={contactComment}
              multiline
              rows={4}
              required={true}
              onChange={(e) => {
                setContactComment(e.target.value);
              }}
            />
          </FormControl>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            Submit
          </Button>
        </Paper>
      )}
    </>
  );
}

export default Contact;

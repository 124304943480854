import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    "& > div ": {
      position: "relative",
      paddingBottom: "56.25%" /* 16:9 */,
      paddingTop: 25,
      height: 0,
    },
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

function Youtube({ id, title }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <iframe
          title={title}
          className={classes.iframe}
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
        />
      </div>
    </div>
  );
}

export default Youtube;

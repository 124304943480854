import React from "react";
import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import processMember from "../data/processMember";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Chip from "@material-ui/core/Chip";

import processNodes from "../data/processNodes";
import { ProviderContext } from "./Main";
import AnchorNav from "../components/AnchorNav";
import ImageBanner from "../components/ImageBanner";
import Subtitle from "../components/Subtitle";
import NextPrev from "../components/NextPrev";
import PageHeader from "../components/PageHeader";
import ToolsCard from "../components/ToolsCard";
import Links from "../components/Links";
import Highlight from "../components/Highlight";
import ContactForm from "../components/ContactForm";
import BoardBreadcrumb from "../components/BoardBreadcrumb";

import CallToAction from "../components/CallToAction";
import SectionHeader from "../components/SectionHeader";
import ContentRow from "../components/ContentRow";
import VCard from "../components/VCard";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    "& .MuiGrid-item": {
      paddingBottom: "0 !important",
    },
    "& + .content-row:not(.section-header)": {
      paddingTop: theme.spacing(0),
    },
  },
  header: {
    "& > *": {
      marginBottom: theme.spacing(2),
      marginTop: 0,
      "&:last-child": {
        marginTop: theme.spacing(3),
      },
    },
    "& > p": {
      fontSize: "1.2rem",
    },
  },
  footer: {
    "& > *": {
      marginTop: `${theme.spacing(3)}px !important`,
      marginBottom: 0,
    },
  },
  billboard: {
    marginBottom: theme.spacing(2),
    fontSize: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
  },
  richtext: {
    textAlign: "center",
    "& > *": {
      marginTop: theme.spacing(3),
      marginBottom: 0,
      "&:first-child": {
        marginTop: 0,
      },
    },
    "& a ": {
      color: theme.palette.secondary.main,
    },
    "& p, & blockquote, & li, & h2, & h3, & h4, & h5, & h6": {
      textAlign: "left",
    },
    "& img": {
      maxWidth: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      height: "auto",
      "&.align-center": {
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
      },
      "&.align-left": {
        float: "left",
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(4),
      },
      "&.align-right": {
        float: "right",
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(4),
      },
    },
    "& p.image-wrapper": {
      textAlign: "center",
    },
    "& li": {
      marginTop: theme.spacing(2),
    },
    "& figure": {
      // width: "100%",
      marginLeft: 0,
      marginRight: 0,
      "& figcaption": {
        // textAlign: "right",
        fontSize: "0.86em",
        marginTop: theme.spacing(0.5),
      },
      "&.align-center": {
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
      },
      "&.align-left": {
        float: "left",
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(4),
      },
      "&.align-right": {
        float: "right",
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(4),
      },
    },
    "& blockquote": {
      backgroundColor: grey["300"],
      marginLeft: 0,
      marginRight: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      fontSize: "1.2rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.1rem",
      },
    },
    "& + nav": {
      marginTop: theme.spacing(3),
    },
  },
  accordion: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  accordionHeader: {
    "& p": {
      fontWeight: theme.typography.fontWeight,
      fontSize: "1.3rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.1rem",
      },
    },
  },
  accordionTab: {
    backgroundColor: grey["300"],
    "& .MuiAccordionDetails-root": {
      paddingTop: 0,
    },
  },
  cta: {
    fontSize: "1.5rem",
    display: "inline-block",
    marginTop: 20,
    color: "white",
  },
  tags: {
    "&> div": {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      alignItems: "center",

      "& > *": {
        margin: theme.spacing(0.5),
        marginLeft: 0,
      },
      "& > b": {
        marginLeft: theme.spacing(2),
        "&:first-child": {
          marginLeft: 0,
        },
      },
    },
  },
}));

const article_meta_tags = {
  tags: {
    label_multi: "Tags",
    label_single: "Tag",
  },
};

function Single({ data, pageContext, type }) {
  const classes = useStyles();
  const providerContext = React.useContext(ProviderContext);
  let article = {};
  article = processNodes(
    { edges: [{ node: data.article }] },
    data.site.siteMetadata.drupalUrl,
    pageContext,
    {
      node__article: data.news,
    },
    providerContext.allNodeRefs
  )[0];

  const drupalUrl = data.site.siteMetadata.drupalUrl;

  const date = article.date_full ? article.date_full : article.date;

  function handleTagClick(e) {
    e.preventDefault();
    let to = e.target.parentElement.getAttribute("href");
    navigate(to);
  }

  const has_sidebar = typeof article.quicklinks !== "undefined";

  const show_root =
    type !== "page" ||
    (article.billboard && article.billboard.length > 0) ||
    (article.anchors && article.anchors.length > 0) ||
    typeof article.accordion !== "undefined" ||
    (typeof article.content !== "undefined" && article.content.length > 0) ||
    typeof article.quicklinks !== "undefined";

  return (
    <>
      {type === "page" && !article.disable_main_header && (
        <PageHeader
          pageTitle={article.title}
          backPath={null}
          background={article.header_background}
        />
      )}
      {article.banner && <ImageBanner src={article.banner} />}
      {type === "knowledge_base_article" && (
        <Container maxWidth="lg">
          <BoardBreadcrumb current={article.title} />
        </Container>
      )}
      {show_root && (
        <div className={classes.root}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={6}
              className={has_sidebar ? "has-sidebar" : "no-sidebar"}
            >
              <Grid item xs={12} md={9}>
                {type !== "page" && type !== "personnel" && (
                  <header className={classes.header}>
                    <Typography variant="h1" component="h1">
                      {article.title}
                    </Typography>
                    <Typography variant="body2">{date}</Typography>
                  </header>
                )}

                {type === "personnel" && (
                  <VCard
                    {...processMember(data.article, drupalUrl)}
                    exclude={["intro"]}
                    single={true}
                    size="lg"
                  />
                )}

                {article.billboard && article.billboard.length > 0 && (
                  <Typography component="blockquote" className={classes.billboard}>
                    {article.billboard}
                  </Typography>
                )}

                {article.anchors && article.anchors.length > 0 && (
                  <AnchorNav anchors={article.anchors} />
                )}

                {article.display_contact_form && (
                  <ContactForm
                    site_data={
                      data.site && data.site.siteMetadata ? data.site.siteMetadata : null
                    }
                  />
                )}

                <div
                  className={classes.richtext}
                  dangerouslySetInnerHTML={{ __html: article.content }}
                ></div>

                {article.accordion && (
                  <div className={classes.accordion}>
                    <Subtitle
                      text={article.accordion.title}
                      id={article.accordion.slug}
                    />
                    {article.accordion.tabs.map(({ title, content, links, id }) => (
                      <Accordion key={id} className={classes.accordionTab}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${id}a-content`}
                          id={`panel${id}a-header`}
                          className={classes.accordionHeader}
                        >
                          <Typography>{title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {content && content.length > 0 && (
                              <div
                                className={classes.richtext}
                                dangerouslySetInnerHTML={{ __html: content }}
                              ></div>
                            )}
                            {links.length > 0 && <Links links={links} />}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                )}
                <footer className={classes.footer}>
                  <nav className={classes.tags}>
                    {Object.keys(article_meta_tags)
                      .filter(
                        (tags_key) => article[tags_key] && article[tags_key].length > 0
                      )
                      .map((tags_key) => {
                        let tags_conf = article_meta_tags[tags_key];
                        let lbl =
                          article[tags_key].length === 1
                            ? tags_conf.label_single
                            : tags_conf.label_multi;
                        return (
                          <div key={lbl}>
                            <b>{`${lbl}:`}</b>
                            {article[tags_key].map((tag) => (
                              <Chip
                                key={tag.id}
                                label={tag.label}
                                variant="outlined"
                                component="a"
                                href={tag.to}
                                onClick={(e) => handleTagClick(e)}
                              />
                            ))}
                          </div>
                        );
                      })}
                  </nav>
                  {article.nextPrev && (
                    <>
                      <Divider />
                      <NextPrev
                        next={article.nextPrev.next}
                        prev={article.nextPrev.prev}
                      />
                    </>
                  )}
                </footer>
              </Grid>
              {has_sidebar && (
                <Grid item xs={12} md={3}>
                  {article.quicklinks && (
                    <ToolsCard
                      title={article.quicklinks.title}
                      description={article.quicklinks.description}
                      links={article.quicklinks.links}
                      styles={{ richtext: classes.richtext }}
                      color="white"
                      sidebar
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Container>
        </div>
      )}
      {article.content_blocks &&
        article.content_blocks.map((block) => {
          if (block.type === "call_to_action") {
            return <CallToAction key={block.id} {...block} />;
          } else if (block.type === "section_header") {
            return <SectionHeader key={block.id} {...block} />;
          } else if (block.type === "content_row") {
            return (
              <ContentRow
                key={block.id}
                {...block}
                styles={{ richtext: classes.richtext }}
              />
            );
          } else if (block.type === "highlight") {
            return (
              <Highlight
                key={block.id}
                {...block}
                styles={{ richtext: classes.richtext }}
              />
            );
          } else {
            return <></>;
          }
        })}
    </>
  );
}

export default Single;

import React from "react";
import { Link } from "gatsby";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from "@material-ui/icons/Launch";
import Container from "@material-ui/core/Container";
import { slugify } from "../util";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  cta: {
    fontSize: "1.3rem",
    display: "inline-block",
    marginTop: 20,
    color: "inherit",
    "& svg": {
      width: "1rem",
      height: "1rem",
      marginLeft: "2px",
    },
    // color: "white",
  },
}));

function HighlightArticle({ title, content, button, styles }) {
  const classes = useStyles();
  return (
    <div className="content-row">
      <Container maxWidth="lg">
        <Paper component="aside" className={classes.root} id={slugify(title)}>
          <Typography component="h2" variant="h3" className={classes.title}>
            {title}
          </Typography>
          <div
            className={styles.richtext}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          {button && (
            <Link to={button.uri} className={classes.cta}>
              {button.title} <LaunchIcon />
            </Link>
          )}
        </Paper>
      </Container>
    </div>
  );
}

export default HighlightArticle;

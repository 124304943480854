import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "gatsby";

function BoardBreadcrumb({ current }) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" to="/board">
        Knowledge Base Home
      </Link>
      {current && <Typography color="textPrimary">{current}</Typography>}
    </Breadcrumbs>
  );
}

export default BoardBreadcrumb;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    overflow: "hidden",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    "&.fgcolor-light": {
      color: "white",
    },
    "&.bgcolor-primary": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.bgcolor-secondary": {
      backgroundColor: theme.palette.secondary.main,
    },
    "&.bgcolor-grey": {
      backgroundColor: theme.palette.grey.main,
    },
    "&.bgcolor-grey-dark": {
      backgroundColor: theme.palette.greyDark.main,
    },
    "& + .content-row": {
      borderTop: "#e0e0e0 3px solid",
    },
  },
  container: {
    "&:not(.align-center) > *": {
      textAlign: "left",
    },
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(3),
      marginBottom: 0,
    },
    "& > hr": {
      height: 2,
      border: "none",
      backgroundColor: theme.palette.tertiary.main,
      marginTop: `${theme.spacing(6)}px !important`,
      marginBottom: `${theme.spacing(6)}px !important`,
      width: "2000rem",
      marginLeft: "-500rem",
    },
  },
}));

function ContentBlock({
  bgcolor,
  fgcolor,
  align,
  children,
  width = "lg",
  style = {},
  extraClasses = "",
}) {
  const classes = useStyles();
  return (
    <div
      className={`content-row ${classes.root} bgcolor-${bgcolor} fgcolor-${fgcolor} align-${align} ${extraClasses}`}
      style={style}
    >
      <Container maxWidth={width} className={`${classes.container} align-${align}`}>
        {children}
      </Container>
    </div>
  );
}

export default ContentBlock;

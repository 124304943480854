import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { yellow } from "@material-ui/core/colors";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import Links from "./Links";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    "&.sidebar": {
      height: "auto",
      backgroundColor: theme.palette.background.annex,
      marginBottom: theme.spacing(2),
    },
    "&.color-yellow": {
      backgroundColor: yellow[200],
    },
    "&.color-annex": {
      backgroundColor: theme.palette.background.annex,
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export default function ToolsCard({
  title,
  description,
  links,
  color,
  styles,
  sidebar = false,
}) {
  const classes = useStyles();

  const card = (
    <Card className={`${classes.root} ${clsx({ sidebar })}  color-${color}`}>
      <CardContent>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {title}
        </Typography>
        <div
          className={styles.richtext}
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        {links.length > 0 && <Links links={links} />}
      </CardContent>
    </Card>
  );
  if (sidebar) return <aside>{card}</aside>;
  return card;
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link } from "gatsby";
import { randomInt } from "../util";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
      },
    },
    subtitle: {
      margin: 0,
      "&.align-center": {
        textAlign: "center",
        width: "100%",
      },
    },
    action: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.secondary.main,
    },
  };
});

function Subtitle({ text, actionLabel, actionPath, align, id }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        variant="h3"
        className={`${classes.subtitle} align-${align}`}
        id={id ? id : `subtitle-${randomInt()}`}
      >
        {text}
      </Typography>
      {actionLabel && actionPath && (
        <Link to={actionPath} className={classes.action}>
          <span>{actionLabel}</span> <ArrowForwardIcon />
        </Link>
      )}
    </div>
  );
}

export default Subtitle;

import React from "react";
import { Typography } from "@material-ui/core";
import ContentBlock from "../components/ContentBlock";
import ImageBanner from "../components/ImageBanner";
import bg from "../images/bg-3.png";

function SectionHeader({ title, banner }) {
  return (
    <>
      {typeof banner !== "undefined" && (
        <ImageBanner src={banner} size="sm"></ImageBanner>
      )}
      <ContentBlock
        bgcolor="secondary"
        fgcolor="light"
        align="center"
        extraClasses="section-header"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <Typography variant="h2" component="h2" id="t-mobile-park">
          {title}
        </Typography>
      </ContentBlock>
    </>
  );
}

export default SectionHeader;

import React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";

import ContentBlock from "../components/ContentBlock";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import mvp_image from "../images/mvp-image3.png";
import arches_blu from "../images/arches-blu.png";

const useStyles = makeStyles((theme) => ({
  subhead: {
    marginTop: theme.spacing(3),
    display: "block",
  },
  logo: {
    height: "auto",
    width: "70%",
    marginBottom: theme.spacing(3),
  },
  cta: {
    fontSize: "1.5rem",
    display: "inline-block",
    marginTop: 20,
    color: "white",
  },
  content: {
    maxWidth: 720,
    marginLeft: "auto",
    marginRight: "auto",
  },
  wrapper: {
    "& > div": {
      position: "relative",
      zIndex: 2,
    },
  },
  colorFilter: {
    position: "absolute",
    display: "block",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // backgroundColor: theme.palette.primary["main"],
    "&.filter-1": {
      zIndex: 1,
      mixBlendMode: "color",
      opacity: "1",
    },
    "&.filter-2": {
      zIndex: 1,
      mixBlendMode: "multiply",
      opacity: "0.7",
    },
    "&.filter-3": {
      zIndex: 1,
      mixBlendMode: "darken",
      opacity: "0.3",
    },
  },
}));

function CallToAction({
  id,
  title,
  logo,
  subhead,
  background,
  heading_type,
  content,
  button,
  header_background,
  is_board = false,
  searchTerm,
  setSearchTerm
}) {
  const classes = useStyles();
  // background = "blue";
  let bg_image = background === "grey" ? mvp_image : arches_blu;
  const bg_color = background === "grey" ? "grey-dark" : "primary";
  const bg_color_hex = background === "grey" ? "#c9c9c9" : "#0c2c56";
  const logo_url = logo && logo.length > 0 ? logo : null;
  if (header_background) {
    bg_image = header_background;
  }

  const inner_content = (
    <ContentBlock
      bgcolor={bg_color}
      fgcolor="light"
      align="center"
      width="md"
      key={id}
      style={{
        backgroundImage: `url(${bg_image})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        paddingTop: 130,
        paddingBottom: 130,
        position: "relative",
        backgroundColor: "grey",
        backgroundBlendMode: "luminosity",
      }}
    >
      <div className={classes.wrapper}>
        <div>
          <header>
            <Typography variant="h2" component={heading_type}>
              {logo_url === null ? (
                title
              ) : (
                <img src={logo_url} alt={title} className={`${classes.logo}`} />
              )}
            </Typography>

            {subhead && (
              <Typography variant="h3" component="strong" className={classes.subhead}>
                {subhead}
              </Typography>
            )}
          </header>
          {content && <Typography className={classes.content}>{content}</Typography>}
          {is_board && (
            <form id="cta-search" className={classes.root} noValidate autoComplete="off">
              <input 
                type="text" 
                placeholder="Enter a topic or question..." 
                onChange={e => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <Button variant="contained" color="primary">
                Search
              </Button>
            </form>
          )}
          {button && (
            <Link to={button.uri} className={classes.cta}>
              {button.title}
            </Link>
          )}
        </div>
        <span
          className={`${classes.colorFilter} filter-1`}
          style={{ backgroundColor: bg_color_hex }}
        />
        <span
          className={`${classes.colorFilter} filter-2`}
          style={{ backgroundColor: bg_color_hex }}
        />
        <span
          className={`${classes.colorFilter} filter-3`}
          style={{ backgroundColor: bg_color_hex }}
        />
      </div>
    </ContentBlock>
  );

  return heading_type === "h1" ? <>{inner_content}</> : <aside>{inner_content}</aside>;
}

export default CallToAction;

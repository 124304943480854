import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "25%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    WebkitBackgroundSize: "cover",
    "&.size-sm": {
      paddingTop: "18%",
    },
  },
}));

function ImageBanner({ src, size }) {
  const classes = useStyles();
  return (
    <aside
      className={`${classes.root} size-${size}`}
      style={{ backgroundImage: `url(${src})` }}
    />
  );
}

export default ImageBanner;
